<template>
  <div
    class="invisible fixed bottom-[-400px] left-4 z-50 transition-all duration-500 hover:bottom-4 md:bottom-[-300px]"
  >
    <div id="embed-iframe"></div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import { spotifyStore } from '../../composables/useSpotify'

const breakpoints = useBreakpoints(breakpointsTailwind)

type PlaybackType = {
  data: {
    duration: number
    isBuffering: boolean
    isPaused: boolean
    position: number
  }
}

const initSpotifyIframe = () => {
  let element = document.getElementById('embed-iframe')

  let options = {
    uri: 'spotify:episode:7makk4oTQel546B0PZlDM5',
  }
  let callback = (EmbedController: any) => {
    spotifyStore.embedController = EmbedController
    EmbedController.addListener('ready', () => {
      // console.log('The Embed has initialized')
      EmbedController.iframeElement.title = 'Spotify Player' // Adds iframe title, re lighthouse warning
    })
    EmbedController.addListener('playback_update', (e: PlaybackType) => {
      spotifyStore.currentSeconds = Math.round(e.data.position / 1000)
      spotifyStore.duration = Math.round(e.data.duration / 1000)
      spotifyStore.isPaused = e.data.isPaused
      spotifyStore.isBuffering = e.data.isBuffering
    })
  }
  //@ts-ignore
  spotifyStore.IFrameAPI.createController(element, options, callback)
}

const addSpotify = () => {
  const desktop = breakpoints.greaterOrEqual('lg')
  const time = desktop.value ? 3000 : 4000

  const existingScript = document.querySelector(
    `script[src="https://open.spotify.com/embed-podcast/iframe-api/v1"]`,
  )

  if (existingScript) {
    initSpotifyIframe()
    return
  }
  //@ts-ignore
  window.onSpotifyIframeApiReady = IFrameAPI => {
    spotifyStore.IFrameAPI = IFrameAPI
    initSpotifyIframe()
  }

  setTimeout(() => {
    const script = document.createElement('script')
    script.src = 'https://open.spotify.com/embed-podcast/iframe-api/v1'
    script.async = true
    document.getElementsByTagName('body')[0].appendChild(script)
  }, time)
}

onMounted(() => addSpotify())
</script>
